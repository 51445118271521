import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";
import { ShowOnLogin, ShowOnLogout } from "../../components/protect/HiddenLink";

const Home = () => {
  return (
    <div className="home">
      <nav className="container">
        <ul className="home-links">
          <ShowOnLogin>
            <li>
              <Link to="/inventory">
                <button className="--btn --btn-primary">
                  Imprimir Etiquetas
                </button>
              </Link>
            </li>
            <li>
              <Link to="/sales">
                <button className="--btn --btn-secondary">
                  Menu
                </button>
              </Link>
            </li>
          </ShowOnLogin>
        </ul>
      </nav>

      <section className="container hero">
        <div className="hero-text">
          <h2>Simplifique a Identificação de Volumes</h2>
          <p>
            Nosso aplicativo otimiza o processo de etiquetagem de volumes para transportadoras e empresas expedidoras, 
            reduzindo erros e aumentando a agilidade.
          </p>

          <ShowOnLogin>
            <div className="hero-buttons">
              <Link to="/login">
                <button className="--btn botao-home">
                  Entrar novamente
                </button>
              </Link>
            </div>
          </ShowOnLogin>

          <ShowOnLogout>
            <div className="hero-buttons">
              <Link to="/login">
                <button className="--btn botao-home">
                  Login
                </button>
              </Link>
            </div>
          </ShowOnLogout>
        </div>

        <div className="hero-image">
          <img src="/homedemo.webp"  style={{ maxWidth: "50%", height: "50%" }} />
        </div>
      </section>
    </div>
  );
};

export default Home;
