import React, { useState } from "react";
import styles from "./auth.module2.scss";
import { BiLogIn, BiShow, BiHide } from "react-icons/bi"; // Importando os ícones
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loginUser, validateEmail } from "../../services/authService";
import { SET_LOGIN, SET_NAME } from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";

// Imagem do lado esquerdo
import loginImage from "../../assets/homedemo.webp";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const { email, password } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const login = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      return toast.error("Todos os campos são obrigatórios.");
    }

    if (!validateEmail(email)) {
      return toast.error("Por favor, insira um e-mail válido.");
    }

    const userData = {
      email,
      password,
    };
    setIsLoading(true);
    try {
      const data = await loginUser(userData);
      console.log(data);
      await dispatch(SET_LOGIN(true));
      await dispatch(SET_NAME(data.name));
      navigate("/inventory");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Erro ao fazer login. Tente novamente.");
    }
  };

  return (
    <div className="login-container">
      {/* Imagem do lado esquerdo */}
      <div className="login-image">
        <img src={loginImage} alt="Login" />
      </div>

      {/* Formulário à direita */}
      <div className="form-container">
        <h1>Etiquetando</h1> {/* Nome do App */}
        <form onSubmit={login}>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Senha</label>
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                required
              />
                <button
                type="button"
                className="toggle-password"
                onClick={togglePasswordVisibility}
                >
                {showPassword ? <BiHide /> : <BiShow />} {/* Ícone de mostrar/ocultar */}
                </button>            
            </div>
          </div>
          <button type="submit" className="btn-primary">
            {isLoading ? <Loader /> : "Entrar"}
          </button>
        </form>


        <div className="footer">
        <div className="footer-links">
            <p className="home-link">
            <Link to="/">- Home</Link>
            </p>
            <p className="forgotpass-link">
            <Link to="/forgot">- Forgot Password</Link>
            </p>
        </div>
        </div>


      </div>
    </div>
  );
};

export default Login;
