import React from "react";

const Footer = () => {
  return (
    <div className="--flex-center --py2">
      <p>Archo Tech</p>
      <p>ἄρχω</p>
    </div>
  );
};

export default Footer;
